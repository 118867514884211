var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"pt-7 pb-3 mb-3"},[_c('v-row',[_c('v-card-title',[_c('h2',{staticClass:"text-xl mb-6"},[_vm._v("Rentabilité des rédacteurs internes")])]),_c('dot-menu',{attrs:{"position":'page-top-right',"excelButton":{
          excelData: _vm.excelData,
          filename: ("redac-inter-" + (this.monthDate) + ".xlsx"),
        }},on:{"exportExcel":_vm.exportExcel}})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{staticClass:"ml-6 mr-6",attrs:{"append-icon":"mdi-magnify","label":"Recherche (par Site, Titre, Redac ou Email)","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{staticClass:"ml-6 mr-6",attrs:{"outlined":"","clearable":"","items":['site', 'redac', 'email', 'title'],"menu-props":{ maxHeight: '200', zIndex: 13 },"label":"Dans la colonne"},model:{value:(_vm.search_column),callback:function ($$v) {_vm.search_column=$$v},expression:"search_column"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"ml-3 mr-3",attrs:{"items":_vm.postItems,"headers":_vm.postsHeaders,"options":_vm.options,"sort-by":_vm.options.sortBy,"sort-desc":_vm.options.sortDesc,"server-items-length":_vm.options.total,"page":_vm.options.page,"pageCount":_vm.numberOfPages},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"truncateCell",attrs:{"title":item.title}},[_c('a',{attrs:{"href":item.url,"target":"_blank"}},[_vm._v(_vm._s(item.title))])])]}},{key:"item.email",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"truncateCell",attrs:{"title":item.email}},[_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(item.email))])])]}},_vm._l((_vm.slotAudienceKeysToFormat.concat( _vm.slotCurrencyKeysToFormat )),function(key){return {key:("item." + key),fn:function(ref){
            var item = ref.item;
return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(_vm.prettyNbs(item[key]))),(_vm.slotCurrencyKeysToFormat.includes(key))?_c('span',[_vm._v(" €")]):_vm._e()])]}}})],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }