<template>
  <div>
    <v-card class="mb-6">
      <v-row class="ml-3 mr-3">
        <v-col>
          <site-group-filter store="audience" />
        </v-col>
        <v-col>
          <date-picker-single-month
            identifier="monthFilter"
            dateInit="current"
            @onSelect="setSelectedMonth"
            dense="dense"
            customClass="ml-4"
            hint=""
          ></date-picker-single-month>
        </v-col>
        <v-col>
          <apply-filters :disabled="isLoading" outlined color="secondary" />
        </v-col>
      </v-row>
      <v-row class="ml-3 mr-3">
        <v-col cols="6">
          <site-filter store="audience" />
        </v-col>
        <v-col cols="6">
          <redac-filter store="audience" />
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import useAxios from "@/hooks/useAxios";
import ApplyFilters from "@/components/common/filters/ApplyFilters.vue";
import DatePickerSingleMonth from "@/components/common/filters/DatePickerSingleMonth.vue";
import SiteFilter from "@/components/common/filters/SiteFilter";
import RedacFilter from "@/components/common/filters/RedacFilter";
import SiteGroupFilter from "@/components/common/filters/SiteGroupFilter";

export default {
  name: "Filters",
  components: {
    ApplyFilters,
    DatePickerSingleMonth,
    SiteFilter,
    SiteGroupFilter,
    RedacFilter,
  },
  data() {
    return {};
  },
  setup() {
    const { isLoading } = useAxios();

    return {
      isLoading,
    };
  },
  methods: {
    setSelectedMonth(month) {
      this.$store.commit("audience/updateMonthDate", month);
    },
  },
  computed: {},
};
</script>

<style></style>
