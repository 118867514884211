<template>
  <div>
    <v-card class="pt-7 pb-3 mb-3">
      <v-card-title>
        <h2 class="text-xl mb-6">{{ getTitle() }}</h2>
      </v-card-title>
      <v-row>
        <v-col cols="8">
          <v-text-field
            class="ml-6 mr-6"
            v-model="search"
            append-icon="mdi-magnify"
            label="Recherche (par Site, Redac ou Email)"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-select
            class="ml-6 mr-6"
            outlined
            clearable
            v-model="search_column"
            :items="selectItems"
            :menu-props="{ maxHeight: '200', zIndex: 13 }"
            label="Dans la colonne"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <Loader v-if="isLoading"></Loader>
          <v-data-table
            class="ml-6 mr-6"
            :items="postItems"
            :headers="headers"
            :options.sync="options"
            :sort-by="options.sortBy"
            :sort-desc="options.sortDesc"
            :server-items-length="options.total"
            :page="options.page"
            :pageCount="numberOfPages"
          >
            <template v-slot:item.group_rentability="{ item }">
              <span
                :style="{ color: item.group_rentability >= 1 ? 'green' : '' }"
                >{{ formatPercent(item.group_rentability) }}</span
              >
            </template>
            <template
              v-for="key in [
                ...slotAudienceKeysToFormat,
                ...slotCurrencyKeysToFormat,
              ]"
              v-slot:[`item.${key}`]="{ item }"
            >
              <span style="white-space: nowrap">
                {{ prettyNbs(item[key])
                }}<span v-if="slotCurrencyKeysToFormat.includes(key)"> €</span>
              </span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import Loader from "@/components/common/Loader.vue";

import useAxios from "@/hooks/useAxios";

import { formatPercent, prettyNbs, round2Dec } from "@/utils/formatting";
import { paramsSerializer } from "@/utils/paramsSerializer";
import debounce from "lodash/debounce";
export default {
  name: "RentaGroupesPostsTable",
  components: {
    Loader,
  },
  props: {
    group_by: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      optionsInitialized: false,
      forceCall: false,
      slotAudienceKeysToFormat: [
        "group_views",
        "group_sessions",
        "sessions_par_article",
      ],
      slotCurrencyKeysToFormat: [
        "average_price",
        "total_price",
        "group_ca",
        "rpm",
      ],
      postItems: [],
      postsHeaders: [
        // { text: "id", value: "id" },
        { text: "name", value: "name", sortable: false },
        { text: "Nb d'articles", value: "total_posts", sortable: true },
        { text: "coût moyen", value: "average_price", sortable: true },
        { text: "coût total", value: "total_price", sortable: true },
        { text: "CA", value: "group_ca", sortable: true },
        { text: "RPM", value: "rpm", sortable: true },
        { text: "% rentabilité", value: "group_rentability", sortable: true },
        { text: "views", value: "group_views", sortable: true },
        { text: "sessions", value: "group_sessions", sortable: true },
        {
          text: "sessions / articles",
          value: "sessions_par_article",
          sortable: true,
        },
      ],
      options: {
        page: 1,
        itemsPerPage: 10,
        total: 10,
        sortBy: [],
        sortDesc: [],
      },
      numberOfPages: 0,
      search: "",
      search_column: "",
      isLoading: false,
    };
  },
  setup() {
    const { axiosGet } = useAxios();

    return {
      axiosGet,
    };
  },
  methods: {
    round2Dec,
    prettyNbs,
    formatPercent,
    debouncedUpdateData: debounce(async function () {
      await this.getData();
    }, 500),
    getPostHeader() {
      if (this.group_by === "redac_id") {
        return [{ text: "email", value: "email" }, ...this.postsHeaders];
      }
      return this.postsHeaders;
    },
    getTitle() {
      return this.group_by === "redac_id"
        ? "Rentabilité par rédacteur"
        : "Rentabilité par site";
    },
    async getData() {
      if (
        (this.filtersApplied === false && this.forceCall === false) ||
        this.isLoading ||
        !this.startDate ||
        this.startDate.length !== 10 ||
        !this.endDate ||
        this.endDate.length !== 10
      ) {
        this.forceCall = false;

        return;
      }
      this.isLoading = true;
      const params = {
        redac_list: this.redacs,
        site_list: this.sites,
        start_date: this.startDate,
        end_date: this.endDate,
        page: this.options.page,
        limit: this.options.itemsPerPage,
        group_by_col: this.group_by,
        search: this.search,
        search_column: this.search_column,
        sort_by: this.options.sortBy,
        sort_desc: this.options.sortDesc,
      };
      const response = await this.axiosGet("adn_renta/grouped", params, {
        paramsSerializer: paramsSerializer,
      });
      this.options.total = response.data.total;
      this.postItems = response.data.data;
      this.numberOfPages = Math.ceil(
        response.data.total / this.options.itemsPerPage
      );
      await this.$store.dispatch("common/updateApplyFilters", false);
      this.isLoading = false;
      this.forceCall = false;
    },
  },
  computed: {
    headers() {
      return this.getPostHeader();
    },
    sites() {
      return this.$store.getters["audience/getSites"];
    },
    startDate() {
      return this.$store.getters["audience/getDates"][0];
    },
    endDate() {
      return this.$store.getters["audience/getDates"][1];
    },
    periodType() {
      return this.$store.getters["audience/getPeriodType"];
    },
    filtersApplied() {
      return this.$store.getters["common/getApplyFilters"];
    },
    redacs() {
      return this.$store.getters["audience/getRedacs"];
    },
    selectItems() {
      if (this.group_by === "redac_id") {
        return ["redac", "email"];
      } else if (this.group_by === "site_id") {
        return ["site"];
      } else {
        return [];
      }
    },
  },
  watch: {
    filtersApplied() {
      this.getData();
    },
    options() {
      if (this.optionsInitialized) {
        // Not on initialization : wait for a real user change
        this.forceCall = true;
      }

      this.optionsInitialized = true;
      this.getData();
    },
    search() {
      this.forceCall = true;
      this.debouncedUpdateData();
    },
    search_column() {
      this.forceCall = true;
      this.debouncedUpdateData();
    },
  },
};
</script>

<style></style>
