<template>
  <v-autocomplete
    outlined
    v-model="selected"
    :items="items"
    :menu-props="{ maxHeight: '400', zIndex: 13 }"
    label="Sélectionner les redacs"
    multiple
    small-chips
    deletable-chips
    :item-text="itemText"
    item-value="id"
  ></v-autocomplete>
</template>

<script>
import isEqual from "lodash/isEqual";

import { computed, onMounted } from "@vue/composition-api";

import useProxy from "@/hooks/useProxy";

const COMPONENT_NAME = "RedacFilter";

/**
 * Either call this component giving an `initialValue` (int[])
 *  and listening on `valueChanged` event,
 * Or call it specifying a `store`
 *  (and eventually `storeGetter`/`storeUpdater`)
 */
export default {
  name: COMPONENT_NAME,
  props: {
    initialValue: {
      type: Array,
      validator(values) {
        return (
          values.length === 0 ||
          values.every((value) => typeof value === "number")
        );
      },
    },
    store: {
      type: String,
      validator(value) {
        /* List of stores having :
          - getters["<store>/<storeGetter>"]
          - dispatch("<store>/<storeUpdater>", payload)
          */
        return ["redac", "audience"].includes(value);
      },
    },
    storeGetter: {
      type: String,
      default: "getRedacs",
    },
    storeUpdater: {
      type: String,
      default: "setRedacs",
    },
  },
  setup(props, { emit }) {
    const { store } = useProxy();

    // =>  METHODS
    const itemText = (item) =>
      `${item.name} (${item.email} - ${item.site_id} - ${item.site_name})`;

    // =>  COMPUTED
    const items = computed(() => store.getters["common/getRedacList"]);

    const viaStore = computed(() => props.store !== undefined);

    const selected = computed({
      get() {
        if (viaStore.value) {
          return store.getters[`${props.store}/${props.storeGetter}`];
        }

        return props.initialValue;
      },
      set(e) {
        if (viaStore.value === false) {
          if (!isEqual(e, props.initialValue)) {
            emit("valueChanged", e);
          }

          return;
        }

        store.dispatch(`${props.store}/${props.storeUpdater}`, e);
      },
    });

    // =>  WATCH

    // =>  HOOKS
    onMounted(async () => {
      await store.dispatch("common/getRedacList");
    });

    // =>  DATA
    return {
      items,
      itemText,
      selected,
    };
  },
};
</script>

<style scoped>
[role="menu"] {
  top: 233px !important;
  right: 0 !important;
}
</style>
