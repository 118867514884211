<template>
  <div>
    <v-card class="pt-7 pb-3 mb-3">
      <v-row>
        <v-card-title>
          <h2 class="text-xl mb-6">Rentabilité des rédacteurs internes</h2>
        </v-card-title>
        <dot-menu
          @exportExcel="exportExcel"
          :position="'page-top-right'"
          :excelButton="{
            excelData,
            filename: `redac-inter-${this.monthDate}.xlsx`,
          }"
        ></dot-menu>
      </v-row>

      <v-row>
        <v-col cols="8">
          <v-text-field
            class="ml-6 mr-6"
            v-model="search"
            append-icon="mdi-magnify"
            label="Recherche (par Site, Titre, Redac ou Email)"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-select
            class="ml-6 mr-6"
            outlined
            clearable
            v-model="search_column"
            :items="['site', 'redac', 'email', 'title']"
            :menu-props="{ maxHeight: '200', zIndex: 13 }"
            label="Dans la colonne"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table
            class="ml-3 mr-3"
            :items="postItems"
            :headers="postsHeaders"
            :options.sync="options"
            :sort-by="options.sortBy"
            :sort-desc="options.sortDesc"
            :server-items-length="options.total"
            :page="options.page"
            :pageCount="numberOfPages"
          >
            <template v-slot:item.title="{ item }">
              <div class="truncateCell" :title="item.title">
                <a :href="item.url" target="_blank">{{ item.title }}</a>
              </div>
            </template>
            <template v-slot:item.email="{ item }">
              <div class="truncateCell" :title="item.email">
                <span style="white-space: nowrap">{{ item.email }}</span>
              </div>
            </template>
            <template
              v-for="key in [
                ...slotAudienceKeysToFormat,
                ...slotCurrencyKeysToFormat,
              ]"
              v-slot:[`item.${key}`]="{ item }"
            >
              <span style="white-space: nowrap">
                {{ prettyNbs(item[key])
                }}<span v-if="slotCurrencyKeysToFormat.includes(key)"> €</span>
              </span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<style scoped>
.truncateCell {
  max-height: 60px;
  max-width: 200px;
  overflow: hidden;
}
</style>

<script>
import DotMenu from "@/components/common/menus/DotMenu";
import { formatPercent, prettyNbs } from "@/utils/formatting";
import { paramsSerializer } from "@/utils/paramsSerializer";
import axios from "@/plugins/axios";
export default {
  name: "InternRentaPostsTable",
  components: {
    DotMenu,
  },

  data() {
    return {
      excelData: new Blob([""], { type: "text/plain" }),
      slotAudienceKeysToFormat: ["views", "sessions"],
      slotCurrencyKeysToFormat: ["price", "ca"],
      postItems: [],
      postsHeaders: [
        { text: "Titre de l'article", value: "title", sortable: false },
        { text: "author", value: "author", sortable: false },
        { text: "email", value: "email", sortable: false },
        { text: "site", value: "site_name", sortable: false },
        { text: "CA", value: "ca", sortable: true },
        { text: "views", value: "views", sortable: true },
        { text: "sessions", value: "sessions", sortable: true },
      ],

      options: {
        page: 1,
        itemsPerPage: 10,
        total: 0,
        sortBy: [],
        sortDesc: [],
      },
      numberOfPages: 0,
      search: "",
      search_column: "",
    };
  },
  methods: {
    prettyNbs,
    formatPercent,
    async getData() {
      const params = {
        redac_list: this.redacs,
        site_list: this.sites,
        month_date: this.monthDate,
        page: this.options.page,
        limit: this.options.itemsPerPage,
        search: this.search,
        search_column: this.search_column,
        sort_by: this.options.sortBy,
        sort_desc: this.options.sortDesc,
      };
      const response = await axios.get("adn_renta/internes", {
        params,
        paramsSerializer: paramsSerializer,
      });
      console.log(response);
      this.options.total = response.data.total;
      this.postItems = response.data.data;
      this.numberOfPages = Math.ceil(
        response.data.total / this.options.itemsPerPage
      );
      this.$store.dispatch("common/updateApplyFilters", false);
    },

    async exportExcel() {
      const params = {
        redac_list: this.redacs,
        site_list: this.sites,
        month_date: this.monthDate,
        search: this.search,
        search_column: this.search_column,
      };

      const response = await axios.get("adn_renta/internes/export", {
        params,
        paramsSerializer: paramsSerializer,
        responseType: "blob", // Important: ensure the response is a Blob
      });

      this.excelData = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
    },
  },
  created() {},
  computed: {
    sites() {
      return this.$store.getters["audience/getSites"];
    },
    monthDate() {
      return this.$store.getters["audience/getMonthDate"];
    },
    filtersApplied() {
      return this.$store.getters["common/getApplyFilters"];
    },
    redacs() {
      return this.$store.getters["audience/getRedacs"];
    },
  },
  watch: {
    filtersApplied() {
      this.getData();
    },
    options() {
      this.getData();
    },
    search() {
      this.getData();
    },
    search_column() {
      this.getData();
    },
  },
};
</script>
