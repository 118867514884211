<template>
  <div>
    <v-card class="pt-7 pb-3 mb-3">
      <v-row>
        <v-card-title>
          <h2 class="text-xl mb-6">Rentabilité des rédacteurs externes</h2>
        </v-card-title>
        <dot-menu
          @exportCsv="exportCSV"
          :position="'page-top-right'"
          :csvButton="{
            csvData,
            filename: `renta-redac-extern-${this.startDate}_${this.endDate}.csv`,
          }"
        ></dot-menu>
      </v-row>

      <v-row>
        <v-col cols="8">
          <v-text-field
            class="ml-6 mr-6"
            v-model="search"
            append-icon="mdi-magnify"
            label="Recherche (par Site, Titre, Redac ou Email)"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-select
            class="ml-6 mr-6"
            outlined
            clearable
            v-model="search_column"
            :items="['site', 'redac', 'email', 'title']"
            :menu-props="{ maxHeight: '200', zIndex: 13 }"
            label="Dans la colonne"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <Loader v-if="isLoading"></Loader>
          <v-data-table
            class="ml-3 mr-3"
            :items="postItems"
            :headers="postsHeaders"
            :options.sync="options"
            :sort-by="options.sortBy"
            :sort-desc="options.sortDesc"
            :server-items-length="options.total"
            :page="options.page"
            :pageCount="numberOfPages"
          >
            <template v-slot:item.title="{ item }">
              <div class="truncateCell" :title="item.title">
                <a :href="item.url" target="_blank">{{ item.title }}</a>
              </div>
            </template>
            <template v-slot:item.email="{ item }">
              <div class="truncateCell" :title="item.email">
                <span style="white-space: nowrap">{{ item.email }}</span>
              </div>
            </template>
            <template v-slot:item.rentability="{ item }">
              <span :style="{ color: item.rentability >= 1 ? 'green' : '' }">{{
                formatPercent(item.rentability)
              }}</span>
            </template>
            <template
              v-for="key in [
                ...slotAudienceKeysToFormat,
                ...slotCurrencyKeysToFormat,
              ]"
              v-slot:[`item.${key}`]="{ item }"
            >
              <span style="white-space: nowrap">
                {{ prettyNbs(item[key])
                }}<span v-if="slotCurrencyKeysToFormat.includes(key)"> €</span>
              </span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<style scoped>
.truncateCell {
  max-height: 60px;
  max-width: 200px;
  overflow: hidden;
}
</style>

<script>
import debounce from "lodash/debounce";

import DotMenu from "@/components/common/menus/DotMenu";
import Loader from "@/components/common/Loader.vue";
import useAxios from "@/hooks/useAxios";
import { formatPercent, prettyNbs } from "@/utils/formatting";
import { paramsSerializer } from "@/utils/paramsSerializer";

export default {
  name: "RentaPostsTable",
  components: {
    DotMenu,
    Loader,
  },
  data() {
    return {
      optionsInitialized: false,
      forceCall: false,
      csvData: "",
      slotAudienceKeysToFormat: ["views", "sessions"],
      slotCurrencyKeysToFormat: ["price", "ca"],
      postItems: [],
      postsHeaders: [
        { text: "Titre de l'article", value: "title", sortable: false },
        { text: "author", value: "author", sortable: false },
        { text: "email", value: "email", sortable: false },
        { text: "site", value: "site_name", sortable: false },
        { text: "coût", value: "price", sortable: true },
        { text: "CA", value: "ca", sortable: true },
        { text: "% rentabilité", value: "rentability", sortable: true },
        { text: "views", value: "views", sortable: true },
        { text: "sessions", value: "sessions", sortable: true },
      ],

      options: {
        page: 1,
        itemsPerPage: 10,
        total: 10,
        sortBy: [],
        sortDesc: [],
      },
      numberOfPages: 0,
      search: "",
      search_column: "",
      isLoading: false,
    };
  },
  setup() {
    const { axiosGet } = useAxios();

    return {
      axiosGet,
    };
  },
  methods: {
    prettyNbs,
    formatPercent,
    debouncedUpdateData: debounce(async function () {
      await this.getData();
    }, 500),
    async getData() {
      if (
        (this.filtersApplied === false && this.forceCall === false) ||
        this.isLoading ||
        !this.startDate ||
        this.startDate.length !== 10 ||
        !this.endDate ||
        this.endDate.length !== 10
      ) {
        return;
      }

      this.isLoading = true;
      const params = {
        redac_list: this.redacs,
        site_list: this.sites,
        start_date: this.startDate,
        end_date: this.endDate,
        page: this.options.page,
        limit: this.options.itemsPerPage,
        search: this.search,
        search_column: this.search_column,
        sort_by: this.options.sortBy,
        sort_desc: this.options.sortDesc,
      };
      const response = await this.axiosGet("adn_renta/", params, {
        paramsSerializer,
      });
      this.options.total = response.data.total;
      this.postItems = response.data.data;
      this.numberOfPages = Math.ceil(
        response.data.total / this.options.itemsPerPage
      );
      await this.$store.dispatch("common/updateApplyFilters", false);
      await this.$store.dispatch(
        "audience/updateRentaGlobalStats",
        response.data.stats
      );
      this.isLoading = false;

      // Force clicking on button again to re-run that request :
      // (we don't call this dispatch earlier to be sure, as state is async, that all listening components could run their requests)
      this.$store.dispatch("common/updateApplyFilters", false);
    },

    async exportCSV() {
      const params = {
        redac_list: this.redacs,
        site_list: this.sites,
        start_date: this.startDate,
        end_date: this.endDate,
        search: this.search,
        search_column: this.search_column,
      };

      const response = await axios.get("adn_renta/export", {
        params,
        paramsSerializer: paramsSerializer,
      });

      this.csvData = response.data;
    },
  },
  computed: {
    sites() {
      return this.$store.getters["audience/getSites"];
    },
    startDate() {
      return this.$store.getters["audience/getDates"][0];
    },
    endDate() {
      return this.$store.getters["audience/getDates"][1];
    },
    periodType() {
      return this.$store.getters["audience/getPeriodType"];
    },
    filtersApplied() {
      return this.$store.getters["common/getApplyFilters"];
    },
    redacs() {
      return this.$store.getters["audience/getRedacs"];
    },
  },
  watch: {
    filtersApplied() {
      this.debouncedUpdateData();
    },
    options() {
      if (this.optionsInitialized) {
        // Not on initialization : wait for a real user change
        this.forceCall = true;
      }

      this.optionsInitialized = true;
      this.debouncedUpdateData();
    },
    search() {
      this.forceCall = true;
      this.debouncedUpdateData();
    },
    search_column() {
      this.forceCall = true;
      this.debouncedUpdateData();
    },
  },
};
</script>
