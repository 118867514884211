<template>
  <div>
    <h1 class="text-2xl mb-6">Rentabilité des articles</h1>
    <v-card class="mb-6 pa-2">
      <v-row class="ma-3">
        <v-switch
          v-model="isExternal"
          label="Internes / Externes"
          inset
        ></v-switch>
      </v-row>
    </v-card>

    <div v-if="isExternal">
      <filters :loading="isLoading"></filters>
      <top-stats-card :loading="isLoading" class="mb-3" />
      <all-posts-table class="mb-3" />
      <grouped-posts-table
        group_by="redac_id"
        class="mb-3"
      ></grouped-posts-table>
      <grouped-posts-table
        group_by="site_id"
        class="mb-3"
      ></grouped-posts-table>
    </div>
    <div v-else>
      <intern-filters></intern-filters>
      <intern-table class="mb-3" />
    </div>
  </div>
</template>
<script>
import filters from "@/components/audience/renta-par-article/filters/Filters.vue";
import topStatsCard from "@/components/audience/renta-par-article/cards/TopStatsCard";
import allPostsTable from "@/components/audience/renta-par-article/tables/allPostsTable.vue";
import groupedPostsTable from "@/components/audience/renta-par-article/tables/groupedPostsTable.vue";
import internTable from "@/components/audience/renta-par-article/tables/internTable.vue";
import internFilters from "@/components/audience/renta-par-article/filters/InternFilters.vue";
import useAxios from "@/hooks/useAxios";
import { saveRmraView } from "@/utils/rmr-analytics";

export default {
  name: "RentaParArticles",
  components: {
    filters,
    internFilters,
    internTable,
    topStatsCard,
    allPostsTable,
    groupedPostsTable,
  },
  data() {
    return {
      isExternal: true,
    };
  },
  setup() {
    const { isLoading } = useAxios();

    return {
      isLoading,
    };
  },
  mounted() {
    saveRmraView(this.$route);
  },
  methods: {},
  computed: {},
  watch: {},
};
</script>
<style></style>
