<template>
  <div>
    <v-card class="mb-6">
      <v-row class="ml-3 mr-3">
        <v-col>
          <site-group-filter store="audience" />
        </v-col>
        <v-col>
          <date-filter store="audience"></date-filter>
        </v-col>
        <v-col>
          <apply-filters :disabled="loading" outlined color="secondary" />
        </v-col>
      </v-row>
      <v-row class="ml-3 mr-3">
        <v-col cols="6">
          <site-filter store="audience" />
        </v-col>
        <v-col cols="6">
          <redac-filter store="audience" />
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
// import useAxios from "@/hooks/useAxios";
import ApplyFilters from "@/components/common/filters/ApplyFilters.vue";
import DateFilter from "@/components/common/filters/DateFilter";
import SiteFilter from "@/components/common/filters/SiteFilter";
import RedacFilter from "@/components/common/filters/RedacFilter";
import SiteGroupFilter from "@/components/common/filters/SiteGroupFilter";
import { defaultPeriodType } from "@/components/audience/perf-per-article/utils/constants";

export default {
  name: "Filters",
  components: {
    ApplyFilters,
    DateFilter,
    SiteFilter,
    SiteGroupFilter,
    RedacFilter,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      periodTypeList: [
        { id: defaultPeriodType, name: "Articles vus" },
        { id: "articles_created", name: "Articles créés" },
      ],
    };
  },
  // setup() {
  //   const { isLoading } = useAxios();

  //   return {
  //     isLoading,
  //   };
  // },
  methods: {
    validatePeriodType(periodType) {
      const allowedValues = this.periodTypeList.map((entry) => entry.id);

      return allowedValues.includes(periodType)
        ? periodType
        : defaultPeriodType;
    },
  },
  computed: {
    periodType: {
      get() {
        const storedValue = this.$store.getters["audience/getPeriodType"];
        const value = this.validatePeriodType(storedValue);
        if (value !== storedValue) {
          this.$store.dispatch("audience/updatePeriodType", value);
        }

        return value;
      },
      set(e) {
        this.$store.dispatch(
          "audience/updatePeriodType",
          this.validatePeriodType(e)
        );
      },
    },
  },
};
</script>

<style></style>
